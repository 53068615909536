import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, distinct, map, Observable, throwError } from "rxjs";
import { Environment } from "../../environments/environment";
import { IPaginationResponse, IRestResponse } from "../core/interface/response.interface";
import { IAddToWalletPayload, IAddToWalletResponse, IAutoPaySubscriptionStatus, ICheckCreditPoints, ICreditAutoPayPayload, ICreditPoint, IUtilizationInfo } from "../interfaces/creditpoints.interface";
import { IInitSubscriptionResponse } from "../interfaces/payment.interface";
import { RestService } from "./rest.service";

@Injectable({
    providedIn: 'root'
})
export class CreditPointService {

    private creditPoint$ = new BehaviorSubject<number>(0)
    public creditPointObs = this.creditPoint$.asObservable().pipe(distinct())

    private creditPayAutoPay$ = new BehaviorSubject<IAutoPaySubscriptionStatus | null>(null)
    public creditPayAutoPayObs = this.creditPayAutoPay$.asObservable()

    set creditPoints(value: number) {
        this.creditPoint$.next(value)
    }
    get creditPoints(): number {
        return this.creditPoint$.getValue()
    }

    set creditPayAutoPayInfo(value: IAutoPaySubscriptionStatus | null) {
        this.creditPayAutoPay$.next(value)
    }
    get creditPayAutoPayInfo() {
        return this.creditPayAutoPay$.getValue()
    }
    constructor(private restService: RestService) { }

    public getCurrentCreditPoints(): Observable<IRestResponse<ICreditPoint>> {
        return this.restService
            .get(Environment.UrlPath + `/payment/credit`, null, true)
            .pipe(
                map((res: any) => res),
                catchError((error) => throwError(error))
            )
    }

    public checkEnoughCreditPoints(templateId: string): Observable<IRestResponse<ICheckCreditPoints>> {
        return this.restService
            .get(Environment.UrlPath + `/payment/credit/messageCharges/${templateId}`, null, true)
            .pipe(
                map((res: any) => res),
                catchError((error) => throwError(error))
            )
    }
    public getCreditPtUtilized(): Observable<IRestResponse<IUtilizationInfo[]>> {
        return this.restService
            .get(Environment.UrlPath + `/credit/utilization`, null, false)
            .pipe(
                map((res: any) => res),
                catchError((error) => throwError(error))
            )
    }
    public addCreditPtToWallet(payload: IAddToWalletPayload): Observable<IRestResponse<IAddToWalletResponse>> {
        return this.restService
            .post(Environment.UrlPath + `/payment/credit`, payload, false)
            .pipe(
                map((res: any) => res),
                catchError((error) => throwError(error))
            )
    }

    public setUpCreditPtAutoPay(payload: ICreditAutoPayPayload): Observable<IRestResponse<IInitSubscriptionResponse>> {
        return this.restService
            .post(Environment.UrlPath + `/payment/subscribe/credit`, payload, false)
            .pipe(
                map((res: any) => res),
                catchError((error) => throwError(error))
            )
    }

    public allSubscriptions(payload: { tenantId: string }): Observable<IRestResponse<IPaginationResponse<IInitSubscriptionResponse[]>>> {
        return this.restService
            .get(Environment.UrlPath + '/payment/search/subscriptions', payload, false)
            .pipe(
                map((res: any) => res),
                catchError((error) => throwError(error))
            )
    }

    public autoPayStatus(tenantId: string): Observable<IRestResponse<IAutoPaySubscriptionStatus>> {
        return this.restService
            .get(Environment.UrlPath + `/payment/autoPay/status/${tenantId}`, null, false)
            .pipe(
                map((res: any) => res),
                catchError((error) => throwError(error))
            )
    }

}